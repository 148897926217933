import React, { useState } from "react";
import "./RatingComponentStyle.css"; // Import the custom CSS file for styling

const RatingComponent = ({
  rating,
  maxLength,
  index,
  questions,
  setQuestions,
  details,
}) => {
  const [hoveredCircle, setHoveredCircle] = useState(null);
  const [activeRating, setActiveRating] = useState(parseInt(rating));
  // Function to generate an array with rating levels (1 to 10) for rendering circles
  const getRatingLevels = () => {
    const ratingLevels = [];
    for (let i = 1; i <= maxLength; i++) {
      ratingLevels.push(i);
    }
    return ratingLevels;
  };
  function onHover(value) {
    setActiveRating(value);
    // console.log(value, "skjks");
  }
  function onNoHover(value) {
    setActiveRating(rating);
    // console.log(value, "skjks");
  }
  function onRatingChange(value) {
    console.log(value, "sdfjsaido", index, questions, setQuestions);
    questions[index].answer = value ? `${value}` : "0";
    setQuestions([...questions]);
  }
  return getRatingLevels().map((level) => {
    const isActive = activeRating >= level;
    const isHovered = hoveredCircle === level;
    const circleClass = isActive ? "circle activeStyle" : "circle normal";

    return (
      <div
        key={level}
        className={circleClass}
        onMouseEnter={() => !details && onHover(level)}
        onMouseLeave={() => !details && onNoHover()}
        onClick={() => onRatingChange(level)}
      >
        {isActive ? (isHovered ? null : level) : level}
      </div>
    );
  });
};

export default RatingComponent;
