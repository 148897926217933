import React, { useEffect, useState } from "react";

import Modal from "@mui/material/Modal";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";

import { FormControlLabel, Box, Checkbox, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { get_root_value } from "src/utils/domUtils";
const fields = [
  "Duration/Pace",
  "Reps",
  "Weight",
  "Distance/Calories",
  "Rest",
  "Tempo",
];
function ParameterModel({ open, setOpen, data, handleChange }) {
  const handleClose = () => {
    setOpen(false);
  };
  const handleTagToggle = (tag) => {
    const newSelectedTags = [...data];

    const index = newSelectedTags.indexOf(tag);
    if (index === -1) {
      newSelectedTags.push(tag);
    } else {
      newSelectedTags.splice(index, 1);
    }
    handleChange(newSelectedTags);
    // setSelectedFields(newSelectedTags);
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        <div
          className={`text-end mt-1 d-flex justify-content-between align-items-center`}
        >
          <DialogTitle
            sx={{
              paddingY: 0,
              color: get_root_value("--portal-theme-primary"),
            }}
          >
            Parameters
          </DialogTitle>

          <IconButton
            className="back-screen-button me-1 "
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/* <DialogTitle>
          <CloseIcon onClick={handleClose} />
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div class="row ">
              {fields.map((tag) => (
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <Box key={tag} sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      size="small"
                      checked={data.includes(tag)}
                      onChange={() => handleTagToggle(tag)}
                      sx={{ padding: "5px" }}
                    />
                    <FormControlLabel
                      control={
                        <Typography variant="body2" sx={{ marginLeft: 1 }}>
                          {tag}
                        </Typography>
                      }
                      label=""
                    />
                  </Box>{" "}
                </div>
              ))}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ParameterModel;
