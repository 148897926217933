import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  PrivateRouteWithLayout,
  // RouteWithLayout,
  PublicRouteWithLayout,
  // PrivateRouteWithLayout,
} from "./components";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import {
  ForgetPassword,
  Programmes,
  ProgrammesDetail,
  GoalStatement,
  Vault,
  Profile,
  Pods,
  Affirmations,
  AddCategory,
  EditCategory,
  Gratitude,
  Quotes,
  Memories,
  MemoriesDetail,
  AddMemories,
  EditMemories,
  DailyActivities,
  Calender,
  SupportTicket,
  LessonsDetail,
  LessonListing,
  ProgrammRecordingDetail,
  AffirmationList,
  AddGratitude,
  EditGratitude,
  AddAffirmation,
  EditAffirmation,
  StudySessionRecordingDetail,
  SupportTicketDetail,
  ConsultantRecordings,
  LandingPage,
  PaymentForm,
  ThanksPage,
  HelpingVideos,
  HelpingVideoDetail,
} from "./pages/index";
import EditProfile from "./pages/Profile/EditProfile";
import Login from "./pages/Login";
import NotFound from "./pages/Page404";
import {
  DeleteNote,
  StudySessionListing,
} from "./components/_dashboard/programmes";
import PodsDetail from "./pages/Pods/PodsDetail";
import ManagePhotos from "./components/_dashboard/goalStatement/ManagePhotos";
import { Payment } from "./components/Payment/Payment";
import BillingCard from "./components/Billing/BillingCard";
import BillingDetail from "./components/Billing/BillingDetails";
import AllTransaction from "./components/Transactions/AllTransaction";
import TransactionInformation from "./components/Transactions/TransactionInformation";
import ManageCard from "./components/ChangeCard/ManageCard";
import ChangeCreditCard from "./components/ChangeCard/ChangeCreditCard";
import NintyDayPlan from "./pages/90DayPlan/NintyDayPlan";
import NintyDayTracker from "./pages/90DayPlan/NintyDayTracker";
import AddNewEarning from "./pages/90DayPlan/AddNewEarning";
import PaymentRequests from "./components/PaymentRequests/PaymentRequests";
import PendingRequests from "./components/PaymentRequests/PendingRequests";
import ActiveRequests from "./components/PaymentRequests/ActiveRequests";
import PodcastDetail from "./pages/Webpages/PodcastDetail";
import DashboardApp from "./pages/DashboardApp";
import LiveEvents from "./pages/dynamiteEvents/LiveEvents";
import LiveEventsDetail from "./pages/dynamiteEvents/LiveEventsDetail";
import LiveEventsLockedDescription from "./pages/dynamiteEvents/LiveEventsLockedDescription";
import DashboardStates from "./pages/DashboardStates/Dashboard";
import CancelSubscription from "./pages/CancelSubscription";
import ActiveSubscriptionList from "./components/PaymentRequests/ActiveSubscriptionList";
import LoginByAdmin from "./pages/LoginByAdmin";
import SettingsLayout from "./layouts/SettingsLayout";
import UserInformation from "./pages/UserInformation/UserInformation";
import UserForms from "./pages/UserInformation/UserForms";
import UserInformationform from "./pages/UserInformation/UserInformationform";
import DetailsFormInformation from "./pages/UserInformation/DetailsFormInformation";
import ProgramCalender from "./pages/Programmes/ProgramCalender";

// import ContentSettingState from "./Hooks/ContentSettingState";

// ----------------------------------------------------------------------
const Authentication = () => {
  if (localStorage.getItem("token")) {
    return <Navigate to="/dashboard"> </Navigate>;
  }
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login"> </Navigate>;
  }
};

export default function Router() {
  return (
    // <ContentSettingState>

    <Routes>
      <Route path="/login-by-admin/:token" element={<LoginByAdmin />} />
      {/* <Route path="/" element={<LandingPage />} /> */}
      {/* <Route path="/:page_slug" element={<LandingPage />} />
      <Route path="/:page_slug/:podcast_slug" element={<PodcastDetail />} />
      <Route path="/:page_slug/payment/:plan_slug" element={<PaymentForm />} />
      <Route path="/:page_slug/thank-you/:plan_slug" element={<ThanksPage />} /> */}
      <Route element={<DashboardLayout />}>
        {<Route path="/" element={<Authentication />} />}
        <Route path="/dashboard" element={<DashboardApp />} />
        <Route path="/dashboard-stats" element={<DashboardStates />} />
        <Route element={<SettingsLayout />}>
          <Route path="/manage-card" element={<ManageCard />} />
          <Route path="/change-card" element={<ChangeCreditCard />} />

          <Route
            path="/pending-request/:request_slug"
            element={<PendingRequests />}
          />
          <Route path="/manage-subscription" element={<CancelSubscription />} />
          <Route
            path="/manage-subscription/:id"
            element={<ActiveSubscriptionList />}
          />
          <Route path="/payment-request" element={<PaymentRequests />} />
          <Route path="/transaction" element={<AllTransaction />} />
          <Route
            path="/active-request/:request_slug"
            element={<ActiveRequests />}
          />
        </Route>
        <Route path="/programmes" element={<Programmes />} />
        <Route path="/programmes/:slug" element={<ProgrammesDetail />} />
        <Route
          path="/programmes-calendar/:slug"
          element={<ProgramCalender />}
        />

        <Route path="/ninety-day-plan" element={<NintyDayPlan />} />
        <Route path="/ninety-day-tracker" element={<NintyDayTracker />} />
        <Route path="/addnewearning" element={<AddNewEarning />} />
        <Route
          path="/programmes/:program_slug/lessons"
          element={<LessonListing />}
        />
        <Route
          path="/study-session/:sesson_slug/study-session-recordings"
          element={<StudySessionListing />}
        />
        <Route
          path="/study-session/:sesson_slug/study-session-recording/:recording_slug"
          element={<StudySessionRecordingDetail />}
        />
        <Route path="/lessons/:lesson_slug" element={<LessonsDetail />} />
        <Route
          path="/recordings/:recording_slug"
          element={<ProgrammRecordingDetail />}
        />
        <Route
          path="/lessons-recordings/:recording_slug"
          element={<ProgrammRecordingDetail />}
        />
        <Route
          path="/programmes/:program_slug/lessons/:lesson_slug/:id"
          element={<DeleteNote />}
        />
        <Route path="/goal-statement" element={<GoalStatement />} />
        <Route path="/replay-library" element={<Vault />} />
        <Route
          path="/consultant-recordings"
          element={<ConsultantRecordings />}
        />
        <Route path="/payment" element={<Payment />} />
        {/* <Route path="/payment-request" element={<PaymentRequests />} /> */}
        {/* <Route path="/manage-subscription" element={<CancelSubscription />} />
        <Route
          path="/manage-subscription/:id"
          element={<ActiveSubscriptionList />}
        /> */}
        <Route path="/profile" element={<Profile />} />
        <Route path="/Information" element={<UserInformation />} />
        <Route path="/forms" element={<UserForms />} />
        <Route
          path="/forms-submition/:slug"
          element={<UserInformationform />}
        />
        <Route
          path="/forms-Details/:slug"
          element={<DetailsFormInformation />}
        />
        <Route path="/Billing" element={<BillingCard />} />
        {/* <Route path="/transaction" element={<AllTransaction />} /> */}
        <Route
          path="/transaction/transaction-detail/:slug"
          element={<TransactionInformation />}
        />
        {/* <Route path="/manage-card" element={<ManageCard />} /> */}
        <Route
          path="/billing/billing-detail/:billslug"
          element={<BillingDetail />}
        />
        <Route path="/live-events/:id" element={<LiveEventsDetail />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/pods" element={<Pods />} />
        <Route path="/affirmations" element={<Affirmations />} />
        <Route
          path="/affirmations/category/add-category"
          element={<AddCategory />}
        />
        <Route
          path="/affirmations/category/edit-category/:id"
          element={<EditCategory />}
        />
        <Route
          path="/affirmations/affirmation-list/:category_slug"
          element={<AffirmationList />}
        />
        <Route path="/pods/pods-detail/:pod_slug" element={<PodsDetail />} />
        {/* <Route
          path="/pending-request/:request_slug"
          element={<PendingRequests />}
        /> */}
        {/* <Route
          path="/active-request/:request_slug"
          element={<ActiveRequests />}
        /> */}
        <Route
          path="/goal-statement/manage-photos"
          element={<ManagePhotos />}
        />
        <Route
          path="/affirmations/add-affirmation"
          element={<AddAffirmation />}
        />
        <Route
          path="/affirmations/edit-affirmation/:id"
          element={<EditAffirmation />}
        />
        <Route path="/gratitude" element={<Gratitude />} />
        <Route path="/gratitude/addgratitude" element={<AddGratitude />} />
        <Route
          path="/gratitude/edit-gratitude/:id"
          element={<EditGratitude />}
        />
        <Route path="/quotes" element={<Quotes />} />
        <Route path="/memories" element={<Memories />} />
        <Route path="/memories/new-memories" element={<AddMemories />} />
        <Route path="/memories/edit-memories/:id" element={<EditMemories />} />
        <Route
          path="/memories/memories_detail/:memory_slug"
          element={<MemoriesDetail />}
        />
        <Route
          path="/live-events-locked/:id"
          element={<LiveEventsLockedDescription />}
        />
        <Route path="/live-events" element={<LiveEvents />} />
        <Route path="/daily_activities" element={<DailyActivities />} />
        <Route path="/calendar" element={<Calender />} />
        <Route path="/support-tickets" element={<SupportTicket />} />
        <Route path="/support-ticket/:id" element={<SupportTicketDetail />} />
        <Route path="/helping-videos" element={<HelpingVideos />} />
        <Route path="/helping-videos/:slug" element={<HelpingVideoDetail />} />
      </Route>
      <Route element={<LogoOnlyLayout />}>
        <Route path="/" element={<Authentication />} />
        {/* {/ <Route path="/register" element={<Register />} /> /} */}
        <Route path="404" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/login-as-customer" element={<LoginAsCustomer />} /> */}
        <Route path="/forget_password" element={<ForgetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      {/* <PublicRouteWithLayout component={Login} exact layout={LogoOnlyLayout} path="/login" />
      <PublicRouteWithLayout component={Register} exact layout={LogoOnlyLayout} path="/register" /> */}
      {
        // / --------------------------------------- Dashboard --------------------------------------- /
      }
      {/* <PrivateRouteWithLayout
        component={DashboardApp}
        exact
        layout={DashboardLayout}
        path="/dashboard/app"
      /> */}
      {
        // / --------------------------------------- Change Password --------------------------------------- /
      }
      {/* <PrivateRouteWithLayout
        component={Products}
        exact
        layout={DashboardLayout}
        path="/dashboard/products"
      /> */}
    </Routes>
    // </ContentSettingState>
  );
}
//   return useRoutes([
//     {
//       path: '/dashboard',
//       element: <DashboardLayout />,
//       children: [
//         { element: <Navigate to="/dashboard/app" replace /> },
//         { path: 'app', element: <DashboardApp /> },
//         { path: 'user', element: <User /> },
//         { path: 'products', element: <Products /> },
//         { path: 'blog', element: <Blog /> }
//       ]
//     },
//     {
//       path: '/',
//       element: <LogoOnlyLayout />,
//       children: [
//         { path: 'login', element: <Login /> },
//         { path: 'register', element: <Register /> },
//         { path: '404', element: <NotFound /> },
//         { path: '/', element: <Navigate to="/dashboard" /> },
//         { path: '*', element: <Navigate to="/404" /> }
//       ]
//     },
//     { path: '*', element: <Navigate to="/404" replace /> }
//   ]);
// }
