import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
// material
import { alpha, styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// components
import { MHidden } from "../../components/@material-extend";
//
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import NotificationsPopover from "./NotificationsPopover";
import { usePGIMode } from "../../Hooks/PGIModeContext";
import { useEffect } from "react";
import {
  ContentSettingApi,
  navbarListAPi,
} from "src/DAL/ContentSetting/ContentSetting";
import { useSnackbar } from "notistack";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { memberDetail } from "src/DAL/Profile/Profile";
import { get_root_value } from "src/utils/domUtils";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 42;

// const RootStyle = styled(AppBar)(({ theme }) => ({
//   boxShadow: "none",
//   backdropFilter: "blur(6px)",
//   WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
//   backgroundColor: "rgba(18, 24, 38, 1)",
//   [theme.breakpoints.up("lg")]: {
//     width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
//   },
// }));
const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: get_root_value("--background-primary-color"),
}));
const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP + 20,
    padding: theme.spacing(0, 3),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { mode, handleChangeMode } = usePGIMode();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <RootStyle>
      <ToolbarStyle sx={{ background: "#fff" }}>
        {/* <MHidden width="lgUp"> */}
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: "text.primary", marginRight: "auto" }}
        >
          <Icon icon={menu2Fill} />
        </IconButton>
        {/* </MHidden> */}

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          <AccountPopover />
          {/* <FormControlLabel
            value="start"
            control={
              <Switch
                color="primary"
                value={mode}
                onChange={(e) => handleChangeMode(e.target.checked)}
              />
            }
            label="Dark Mode"
            labelPlacement="start"
          /> */}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
