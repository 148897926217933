import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import { Box, Card, Stack, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import LockIcon from "@mui/icons-material/Lock";
import DateRangeIcon from "@mui/icons-material/DateRange";
// utils
import Label from "../../Label";
import { s3baseUrl } from "../../../config/config";
import { useEffect, useState } from "react";
import ConfirmationWithDate from "./ConfirmationWithDate";
import moment from "moment";

// ----------------------------------------------------------------------

const ProgrammesImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "auto",
  objectFit: "cover",
});

// ----------------------------------------------------------------------

// const WorkoutExercisesUI = ({ addArray, setaddArray, setModalChangeExe }) => {
const ProgrammesCard = ({ programm, reload }) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(null);

  const addAgreeInSuperSet = () => {
    setOpen(false);

    console.log("kjsadhfkjashdkjfhakj", open);
  };
  const navigate = useNavigate();
  const {
    _id,
    title,
    program_slug,
    short_description,
    program_images,
    locked_program_info,
    no_of_lesson,
    total_lesson_duration,
    program_lock_status,
  } = programm;
  const handleNavigateCalendar = (value) => {
    console.log("edit value", value);
    if (!value?.start_date && value?.program_length_type !== "limited") {
      navigate(`/programmes-calendar/${value?._id?.program_slug}`, {
        state: value,
      });
    } else if (value?.start_date) {
      navigate(`/programmes-calendar/${value?._id?.program_slug}`, {
        state: value,
      });
    } else setOpen(true);
  };

  return (
    <div className="h-100">
      <ConfirmationWithDate
        open={open}
        programId={programm?._id?._id}
        setOpen={setOpen}
        title={"Are you sure you want to take this action?"}
        handleAgree={addAgreeInSuperSet}
        reload={reload}
      />
      <Card
        className="programm-card  secondary-background"
        sx={{
          cursor:
            !programm?.start_date && programm?.program_length_type == "limited"
              ? ""
              : "pointer",
          position: "relative",
          height: "100%",
        }}
        onClick={
          !programm?.start_date && programm?.program_length_type == "limited"
            ? ""
            : () => handleNavigateCalendar(programm)
        }
      >
        <Box
          sx={{
            position: "relative",
            cursor:
              !programm?.start_date &&
              programm?.program_length_type == "limited"
                ? ""
                : "pointer",
          }}
        >
          {/* {!programm?.start_date &&
          programm?.program_length_type == "limited" ? (
            <button
              variant="contained"
              className="small-contained-button program-lock-icon"
              style={{
                zIndex: 9,
                top: "45%",
                left: "22%",
                fontSize: "14px",
                position: "absolute",
                padding: "10px 20px",
              }}
            >
              <DateRangeIcon className="lock-icon-color" />
              &nbsp;&nbsp;Start program
            </button>
          ) : (
            ""
          )} */}
          <ProgrammesImgStyle
            alt={title}
            src={s3baseUrl + programm?._id?.program_images?.thumbnail_2}
          />
        </Box>

        <Stack spacing={2} className="programme-content">
          <h3
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          ></h3>
          <p className="programme-card-desc mb-4">{short_description}</p>
          <div className="card-button lesson-text d-flex justify-content-between align-items-center">
            <p className="programme-duration">
              <span>{no_of_lesson} Workouts</span>
            </p>
            <p className="programme-duration me-3">
              {!programm?.start_date &&
                programm?.program_length_type == "limited" && (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => handleNavigateCalendar(programm)}
                  >
                    start program
                  </Button>
                )}
              {programm?.start_date &&
                programm?.program_length_type == "limited" && (
                  <p className="programme-duration">
                    <span>
                      Started On{" "}
                      {moment(programm?.start_date).format("DD-MM-YYYY")}
                    </span>
                  </p>
                )}
            </p>
          </div>
        </Stack>
      </Card>
    </div>
  );
};
export default ProgrammesCard;
