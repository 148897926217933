import {
  CircularProgress,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";

import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { ExerciseListingApi } from "src/DAL/exercise/exercise";

import faker from "faker";

import ToshowExercise from "./component/modalToshowExercise";

import moment from "moment";
import {
  add_program_workout_api,
  update_program_workout_api,
} from "src/DAL/programmeWorkout/programmeWorkout";
import WorkoutExercisesUI from "./WorkoutExercisesUI";
import WorkoutExercisesAnswerUI from "./WorkoutExercisesAnswerUI";
import { Icon } from "@iconify/react";
// import Typography from "src/theme/overrides/Typography";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));
const finalSpaceCharacters = [
  {
    id: "gary",
    name: "Gary Goodspeed",
    thumb: "/images/gary.png",
  },
  {
    id: "cato",
    name: "Little Cato",
    thumb: "/images/cato.png",
  },
  {
    id: "kvn",
    name: "KVN",
    thumb: "/images/kvn.png",
  },
  {
    id: "mooncake",
    name: "Mooncake",
    thumb: "/images/mooncake.png",
  },
  {
    id: "quinn",
    name: "Quinn Ergon",
    thumb: "/images/quinn.png",
  },
];

const AddWorkoutCalender = forwardRef((props, ref) => {
  // console.log(addArray, setaddArray, "dflkadsjjkfahds");
  const [characters, updateCharacters] = useState(finalSpaceCharacters);
  const { state } = useLocation();
  const myInputRef = useRef(null);
  const [serchText, setSerchText] = useState("");
  const [comment, setCommnet] = useState("");
  const [addArray, setaddArray] = useState([
    {
      exercise: "",

      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          rest: "",
          tempo: "",
        },
      ],
      parameters: [
        "Duration/Pace",
        "Reps",
        "Weight",
        "Distance/Calories",
        "Rest",
        "Tempo",
      ],
      description: "",
      _id: faker.datatype.uuid(),
      superSet: [],
      superset_sets: "",
    },
  ]);
  const [original, setOrignial] = useState();
  const [addArrayExtra, setaddArrayExtra] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const [exercise, setExercise] = useState([]);
  const [workoutTitle, setWorkoutTitle] = useState("");

  const [detailsData, setDetailsData] = useState("");
  const [openDetails, setOpenDetails] = useState(false);

  const classes = useStyles();

  const [isFocused, setIsFocused] = useState(false);
  const [isFocused1, setIsFocused1] = useState(false);
  const [workoutDescription, setWorkoutDescription] = useState("");
  const handleAdd = () => {
    props.setModalChangeExe(true);
    let list = addArrayExtra;
    list.splice(addArrayExtra.length, 0, {
      exercise: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          distance: "",
          distanceType: "m",
          reps: "",
          rest: "",
          timeType: "sec",
          restType: "sec",
          tempo: "",
        },
      ],
      is_extra: true,
      is_completed: true,
      description: "",
      superSet: [],
      superset_sets: "",
      parameters: [
        "Duration/Pace",
        "Reps",
        "Weight",
        "Distance/Calories",
        "Rest",
        "Tempo",
      ],
    });

    setaddArrayExtra([...list]);
  };
  useImperativeHandle(ref, () => ({
    childButtonClick() {
      handleSubmit();
      console.log("Button clicked in child component");
      // Add your logic or call other functions here
    },
    childButtonClick2() {
      console.log("Button2 clicked in child component");

      handleSubmit().then((formDataObject) => {
        if (formDataObject) props.setWorkoutAsTem(formDataObject);
      });
    },
    childButtonClickUpdate() {
      console.log("Button Update clicked in child component");
      handleSubmitUpdate();
    },
  }));

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleFocus1 = () => {
    setIsFocused1(true);
  };

  const handleBlur1 = () => {
    setIsFocused1(false);
  };

  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);
    setaddArray(delValue);
  };

  // console.log(level, "levellevellevel");

  function handleOnDragEnd(result) {
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setaddArray(items);
  }

  const getExerciseList = async () => {
    let result = await ExerciseListingApi(0, 100, serchText);
    if (result.code == 200) {
      setIsLoading(false);

      setExercise(result?.exercises);
    }
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    setIsLoading(true);
    let exerciesArray = addArray.map((item) => {
      let data = {
        exercise_info: {
          _id: item?.superSet[0]?.exercise?._id
            ? item?.superSet[0]?.exercise?._id
            : item.exercise?._id,
          title: item?.superSet[0]?.exercise?.title
            ? item?.superSet[0]?.exercise?.title
            : item.exercise?.title,
          image: item?.superSet[0]?.exercise?.image
            ? item?.superSet[0]?.exercise?.image
            : item.exercise?.image,
        },
        title: item?.title,
        description: item.description,
        parameters: item.parameters,
      };
      if (item.superset_sets) {
        data["superset_sets"] = item.superset_sets;
      }
      if (item.set) {
        data["sets"] = [];
        if (item.set.length > 0) {
          data["sets"] = item.set.map((item) => {
            let data = {};
            if (item.vdot) {
              data["vdot"] = item.vdot;
            }
            if (item.race_type) {
              data["race_type"] = item.race_type;
            }
            if (item.pace_type) {
              data["pace_type"] = item.pace_type;
            }
            if (item.pace) {
              data["pace_low"] = item.pace;
            }
            if (item.paceUp) {
              data["pace_up"] = item.paceUp;
            }
            if (item.optionsType) {
              data["pace_options"] = item.optionsType;
            }
            if (item.reps) {
              data["reps"] = item.reps;
            }
            if (item.rest) {
              data["rest"] = item.rest;
            }
            if (item.weight) {
              data["weight"] = item.weight;
            }
            if (item.time) {
              data["duration"] = item.time;
            }
            if (item.weightType) {
              data["weight_unit"] = item.weightType;
            }
            if (item.distanceType) {
              data["distance_unit"] = item.distanceType;
            }
            if (item.timeType) {
              data["duration_unit"] = item.timeType;
            }
            if (item.restType) {
              data["rest_unit"] = item.restType;
            }
            if (item.tempo) {
              data["tempo"] = item.tempo;
            }

            return data;
          });
        }
      }

      if (item.superSet) {
        data["sub_exercises"] = [];
        if (item.superSet.length > 0) {
          data["sub_exercises"] = item.superSet.map((item) => {
            let data = {
              exercise_info: {
                _id: item.exercise?._id,
                title: item.exercise?.title,
                image: item.exercise?.image,
              },
              title: item.exercise?.title,
              description: item.description,
              parameters: item.parameters,
            };
            if (item.set) {
              data["sets"] = [];
              if (item.set.length > 0) {
                data["sets"] = item.set.map((item) => {
                  let data = {};
                  if (item.reps) {
                    data["reps"] = item.reps;
                  }
                  if (item.rest) {
                    data["rest"] = item.rest;
                  }
                  if (item.weight) {
                    data["weight"] = item.weight;
                  }
                  if (item.time) {
                    data["duration"] = item.time;
                  }
                  if (item.weightType) {
                    data["weight_unit"] = item.weightType;
                  }
                  if (item.distanceType) {
                    data["distance_unit"] = item.distanceType;
                  }
                  if (item.timeType) {
                    data["duration_unit"] = item.timeType;
                  }
                  if (item.restType) {
                    data["rest_unit"] = item.restType;
                  }
                  if (item.tempo) {
                    data["tempo"] = item.tempo;
                  }
                  return data;
                });
              }
            }

            return data;
          });
        }
      }
      return data;
    });
    const formDataObject = {
      title: workoutTitle,
      workout_type: null,
      description: workoutDescription,
      status: true,
      exercises: exerciesArray,
      program_id: props.programme?._id,
      workout_day: props.day ? props?.day : 1,
      workout_date: moment(props.date).format("YYYY-MM-DD"),
      comment: comment,
    };
    console.log(formDataObject, "dslfkdjsaa");

    const result = await add_program_workout_api(formDataObject);
    if (result.code === 200) {
      props?.reloadList();
      setIsLoading(false);
      props.setOpen(false);
      enqueueSnackbar(result.message, { variant: "success" });
      return formDataObject;
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSubmitUpdate = async (e) => {
    e?.preventDefault();
    setIsLoading(true);
    let isError = false;

    let fullArray = [...addArray, ...addArrayExtra];
    console.log(fullArray, "adkjfklsdhkjfah");
    let exerciesArray = fullArray.map((item, mainIn) => {
      if (
        item.type != "emom" &&
        item.type != "amrap" &&
        item.type != "something_else" &&
        !item.exercise?._id &&
        item.superSet.length == 0
      ) {
        enqueueSnackbar("please select an exercise", { variant: "error" });
        isError = true;
      }
      if (item.type == "something_else" && !item?.title) {
        enqueueSnackbar("please add name", {
          variant: "error",
        });
        isError = true;
      }
      let data = {
        is_completed: item?.is_completed,
        is_modified: item?.is_modified,
        is_extra: item?.is_extra,
        exercise_info: {
          _id: item?.superSet[0]?.exercise?._id
            ? item?.superSet[0]?.exercise?._id
            : item.exercise?._id,
          title: item?.superSet[0]?.exercise?.title
            ? item?.superSet[0]?.exercise?.title
            : item.exercise?.title,
          image: item?.superSet[0]?.exercise?.image
            ? item?.superSet[0]?.exercise?.image
            : item.exercise?.image,
        },
        title: item?.title,
        description: item.description,
        parameters: item.parameters,
      };
      if (item?._id) {
        data["_id"] = item?._id;
      }
      if (item?.last_amrap_exercise) {
        data["last_amrap_exercise"] = item?.last_amrap_exercise;
      }
      if (item?.last_amrap_exercise_rounds) {
        data["last_amrap_exercise_rounds"] = item?.last_amrap_exercise_rounds;
      }
      if (item.completedRounds) {
        data["amrap_completed_rounds"] = item.completedRounds;
      }
      if (item.superset_sets) {
        data["superset_sets"] = item.superset_sets;
      }
      if (item.rounds) {
        data["emom_rounds"] = item.rounds;
      }
      if (item.type) {
        data["exercise_type"] = item.type;
      }
      if (item.time_interval) {
        data["interval_duration"] = item.time_interval;
      }
      if (item.set) {
        data["sets"] = [];
        if (item.set.length > 0) {
          data["sets"] = item.set.map((item) => {
            let data = {};
            if (item.vdot) {
              data["vdot"] = item.vdot;
            }
            if (item.race_type) {
              data["race_type"] = item.race_type;
            }
            if (item.pace_type) {
              data["pace_type"] = item.pace_type;
            }
            if (item.pace) {
              data["pace_low"] = item.pace;
            }
            if (item.paceUp) {
              data["pace_up"] = item.paceUp;
            }
            if (item.optionsType) {
              data["pace_options"] = item.optionsType;
            }
            if (item.reps) {
              data["reps"] = item.reps;
            }
            if (item.rest) {
              data["rest"] = item.rest;
            }
            if (item.weight) {
              data["weight"] = item.weight;
            }
            if (item.time) {
              data["duration"] = item.time;
            }
            if (item.weightType) {
              data["weight_unit"] = item.weightType;
            }
            if (item.distance) {
              data["distance"] = item.distance;
            }
            if (item.distanceType) {
              data["distance_unit"] = item.distanceType;
            }
            if (item.timeType) {
              data["duration_unit"] = item.timeType;
            }
            if (item.restType) {
              data["rest_unit"] = item.restType;
            }
            if (item.tempo) {
              data["tempo"] = item.tempo;
            }
            return data;
          });
        }
      }
      if (item.type == "emom" || item.type == "amrap") {
        data["emom_amrap"] = [];
        if (item.emomSets.length > 0) {
          item.emomSets.map((item1, ind) => {
            console.log(item1, "sjdkfksjdka");
            if (item1.length > 0) {
              data["emom_amrap"][ind] = item1.map((item2) => {
                if (isError) {
                  return;
                }
                if (!item2.exercise?._id) {
                  if (item.type == "emom") {
                    enqueueSnackbar(
                      `please select an exercise in EMOM,  EMOM placed on ${
                        mainIn + 1
                      } position. `,
                      {
                        variant: "error",
                      }
                    );
                  }
                  if (item.type == "amrap") {
                    enqueueSnackbar(
                      `please select an exercise in AMRAP, AMRAP placed on ${
                        mainIn + 1
                      } position. `,
                      {
                        variant: "error",
                      }
                    );
                  }

                  isError = true;

                  return;
                }
                let data = {
                  exercise_info: {
                    _id: item2.exercise?._id,
                    title: item2.exercise?.title,
                    image: item2.exercise?.image,
                  },
                  title: item2.exercise?.title,
                  description: item2.description,
                  parameters: item2.parameters,
                };
                if (item2?._id) {
                  data["_id"] = item2?._id;
                }
                if (item2.set) {
                  data["sets"] = [];
                  if (item2.set.length > 0) {
                    data["sets"] = item2.set.map((item3) => {
                      let data = {};
                      if (item3.reps) {
                        data["reps"] = item3.reps;
                      }
                      if (item3.rest) {
                        data["rest"] = item3.rest;
                      }
                      if (item3.weight) {
                        data["weight"] = item3.weight;
                      }
                      if (item3.time) {
                        data["duration"] = item3.time;
                      }
                      if (item3.weightType) {
                        data["weight_unit"] = item3.weightType;
                      }
                      if (item3.distance) {
                        data["distance"] = item3.distance;
                      }
                      if (item3.distanceType) {
                        data["distance_unit"] = item3.distanceType;
                      }
                      if (item3.timeType) {
                        data["duration_unit"] = item3.timeType;
                      }
                      if (item3.restType) {
                        data["rest_unit"] = item3.restType;
                      }
                      if (item3.tempo) {
                        data["tempo"] = item3.tempo;
                      }
                      return data;
                    });
                  }
                }

                return data;
              });
            } else {
              if (item.type == "emom") {
                enqueueSnackbar(
                  `please add a Movement on ${
                    ind + 1
                  } Interval in EMOM, EMOM placed on ${mainIn + 1} position.`,
                  {
                    variant: "error",
                  }
                );
              }
              if (item.type == "amrap") {
                enqueueSnackbar(
                  `please add a Movement in AMRAP,  placed on ${
                    mainIn + 1
                  } position.`,
                  {
                    variant: "error",
                  }
                );
              }

              isError = true;

              return;
            }
          });
        }
      }
      if (item.superSet) {
        data["sub_exercises"] = [];
        if (item.superSet.length > 0) {
          data["sub_exercises"] = item.superSet.map((item) => {
            if (isError) {
              return;
            }
            if (!item.exercise?._id) {
              enqueueSnackbar(
                `please select an exercise in Superset,  placed on ${
                  mainIn + 1
                } position.`,
                {
                  variant: "error",
                }
              );

              isError = true;
              return;
            }
            let data = {
              is_completed: item?.is_completed,
              is_modified: item?.is_modified,
              is_extra: item?.is_extra,

              exercise_info: {
                _id: item.exercise?._id,
                title: item.exercise?.title,
                image: item.exercise?.image,
              },
              title: item.exercise?.title,
              description: item.description,
              parameters: item.parameters,
            };
            if (item?._id) {
              data["_id"] = item?._id;
            }
            if (item.set) {
              data["sets"] = [];
              if (item.set.length > 0) {
                data["sets"] = item.set.map((item) => {
                  let data = {};
                  if (item.reps) {
                    data["reps"] = item.reps;
                  }
                  if (item.rest) {
                    data["rest"] = item.rest;
                  }
                  if (item.weight) {
                    data["weight"] = item.weight;
                  }
                  if (item.time) {
                    data["duration"] = item.time;
                  }
                  if (item.weightType) {
                    data["weight_unit"] = item.weightType;
                  }
                  if (item.distance) {
                    data["distance"] = item.distance;
                  }
                  if (item.distanceType) {
                    data["distance_unit"] = item.distanceType;
                  }
                  if (item.timeType) {
                    data["duration_unit"] = item.timeType;
                  }
                  if (item.restType) {
                    data["rest_unit"] = item.restType;
                  }
                  if (item.tempo) {
                    data["tempo"] = item.tempo;
                  }
                  return data;
                });
              }
            }

            return data;
          });
        }
      }
      return data;
    });
    if (isError) {
      setIsLoading(false);
      props.setIsLoading(false);
      return;
    }
    const formDataObject = {
      _id: props?.editData?._id,
      exercises: exerciesArray,
      comment: comment,
    };
    console.log(formDataObject, "dslfkdjsaa");

    const result = await update_program_workout_api(formDataObject);
    if (result.code === 200) {
      props?.reloadList();
      setIsLoading(false);
      props?.setOpen(false);

      enqueueSnackbar(result.message, { variant: "success" });
      return formDataObject;
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChangeUp = (value) => {
    if (value == 0) {
      enqueueSnackbar("You are already on Top !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value - 1, 0, reorderedItem);
    setaddArray(items);
  };

  const handleSelectExercise = (value) => {
    const list = [...addArray];
    list[detailsData]["exercise"] = value;
    list[detailsData]["description"] = value?.instructions;
    setaddArray(list);
  };
  const handleChangeDown = (value) => {
    console.log(value, "fkdshjkfahdkjha", addArray.length);
    if (value >= addArray.length - 1) {
      enqueueSnackbar("You are already on Bottom !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value + 1, 0, reorderedItem);
    setaddArray(items);
  };

  useEffect(() => {
    // getExerciseList();
  }, [serchText]);
  useEffect(() => {
    if (props?.editData) {
      setWorkoutTitle(
        props?.editData?.workout_title
          ? props?.editData?.workout_title
          : props?.editData?.workout_title
      );
      setWorkoutDescription(props?.editData?.workout_id?.description);
      setCommnet(props?.editData?.comment);
      setExercise(props?.editData?.exercises);
      let givenExercise = props?.editData?.exercises
        .map((item) => {
          if (!item?.is_extra) {
            let SubSet = item?.sub_exercises?.map((item) => {
              return {
                _id: item?._id,
                exercise: item?.exercise_info,
                is_completed: item?.is_completed,
                is_modified: item?.is_modified,
                is_extra: item?.is_extra,
                // open: true,
                set: item?.sets.map((item1) => {
                  return {
                    time: item1?.duration,
                    weight: item1?.weight,
                    weightType: item1?.weight_unit,
                    reps: item1?.reps,
                    rest: item1?.rest,
                    distance: item1?.distance,
                    distanceType: item1?.distance_unit,
                    timeType: item1?.duration_unit,
                    restType: item1?.rest_unit,
                    tempo: item1?.tempo,
                  };
                }),
                rounds: 10,
                emomSets: [[]],
                type: item.exercise_type ? item.exercise_type : "general",
                parameters: item?.parameters,
                description: item?.description,
                superSet: [],
              };
            });
            let emomAndAmrapSets = item?.emom_amrap?.map((item1, ind) => {
              let interval = item1?.map((item2) => {
                const repeatedSets = Array.from(
                  {
                    length: item.exercise_type == "emom" ? item.emom_rounds : 1,
                  },
                  (_, index) => {
                    return {
                      time: item2?.sets[0]?.duration,
                      weight: item2?.sets[0]?.weight,
                      weightType: item2?.sets[0]?.weight_unit,
                      reps: item2?.sets[0]?.reps,
                      rest: item2?.sets[0]?.rest,
                      distance: item2?.sets[0]?.distance,
                      distanceType: item2?.sets[0]?.distance_unit,
                      timeType: item2?.sets[0]?.duration_unit,
                      restType: item2?.sets[0]?.rest_unit,
                      tempo: item2?.sets[0]?.tempo,
                    };
                  }
                );
                return {
                  _id: item2?._id,
                  exercise: item2?.exercise_info,
                  set: repeatedSets,

                  parameters: item2?.parameters,
                  description: item2?.description,
                  superSet: [],
                };
              });
              return interval;
            });
            return {
              _id: item?._id,
              title: item.title,
              last_amrap_exercise: item?.last_amrap_exercise,
              last_amrap_exercise_rounds: item?.last_amrap_exercise_rounds,
              exercise: item?.exercise_info,
              is_completed: item?.is_completed,
              is_modified: item?.is_modified,
              is_extra: item?.is_extra,
              // open: true,
              set: item?.sets.map((item1) => {
                return {
                  time: item1?.duration,
                  weight: item1?.weight,
                  weightType: item1?.weight_unit,
                  reps: item1?.reps,
                  rest: item1?.rest,
                  distance: item1?.distance,
                  distanceType: item1?.distance_unit,
                  timeType: item1?.duration_unit,
                  restType: item1?.rest_unit,
                  tempo: item1?.tempo,
                  vdot: item1?.vdot ? item1?.vdot : props?.member_vdot,
                  race_type: item1?.race_type ? item1?.race_type : "m",
                  pace_type: item1?.pace_type ? item1?.pace_type : "m_km",
                  pace: item1?.pace_low ? item1?.pace_low : 423,
                  paceUp: item1?.pace_up ? item1?.pace_up : 423,
                  optionsType:
                    item1?.pace_options && item1?.pace_options.length > 0
                      ? item1?.pace_options
                      : [
                          { title: "KM", key: "m_km" },
                          { title: "MILE", key: "m_mi" },
                        ],
                };
              }),
              rounds: item.emom_rounds ? item.emom_rounds : 10,
              completedRounds: item.amrap_completed_rounds,
              type: item.exercise_type ? item.exercise_type : "general",
              time_interval: item.interval_duration,
              emomSets:
                emomAndAmrapSets && emomAndAmrapSets.length > 0
                  ? emomAndAmrapSets
                  : [[]],
              parameters: item?.parameters,
              description: item?.description,
              superSet: SubSet,
              superset_sets: item?.superset_sets,
            };
          }
          return [];
        })
        .flat();
      let givenExerciseExtra = props?.editData?.exercises
        .map((item) => {
          if (item?.is_extra) {
            let SubSet = item?.sub_exercises?.map((item) => {
              return {
                _id: item?._id,
                exercise: item?.exercise_info,
                is_completed: item?.is_completed,
                is_modified: item?.is_modified,
                is_extra: item?.is_extra,
                set: item?.sets.map((item1) => {
                  return {
                    time: item1?.duration,
                    weight: item1?.weight,
                    weightType: item1?.weight_unit,
                    reps: item1?.reps,
                    rest: item1?.rest,
                    distance: item1?.distance,
                    distanceType: item1?.distance_unit,
                    timeType: item1?.duration_unit,
                    restType: item1?.rest_unit,
                    tempo: item1?.tempo,
                  };
                }),
                description: item?.description,
                parameters: item?.parameters,
                superSet: [],
              };
            });

            return {
              _id: item?._id,
              exercise: item?.exercise_info,
              is_completed: item?.is_completed,
              is_modified: item?.is_modified,
              is_extra: item?.is_extra,
              set: item?.sets.map((item1) => {
                return {
                  time: item1?.duration,
                  weight: item1?.weight,
                  weightType: item1?.weight_unit,
                  reps: item1?.reps,
                  rest: item1?.rest,
                  distance: item1?.distance,
                  distanceType: item1?.distance_unit,
                  timeType: item1?.duration_unit,
                  restType: item1?.rest_unit,
                  tempo: item1?.tempo,
                };
              }),
              parameters: item?.parameters,
              description: item?.description,
              superSet: SubSet,
              superset_sets: item?.superset_sets,
            };
          }
          return [];
        })
        .flat();

      console.log(givenExercise, "sdkfhjkdshkafj");
      setaddArray(givenExercise);

      setOrignial(JSON.parse(JSON.stringify(givenExercise)));
      setaddArrayExtra(givenExerciseExtra);
    }
  }, [props?.editData]);
  console.log(exercise, "dfkjdshfja", props?.editData);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(addArray, "addArray ok");
  return (
    <div className="container" style={{ minWidth: "936px" }}>
      <ToshowExercise
        open={openDetails}
        setOpen={setOpenDetails}
        handleChange={handleSelectExercise}
      />
      <div className=" ">
        <div className="row">
          {" "}
          <div className="col-10">
            {/* <h1 className="programmes-heading">{state?.title}</h1> */}
            <FormControl
              className={!isFocused ? classes.customInput : ""}
              sx={{ width: "100%" }}
            >
              <h2>{workoutTitle}</h2>
            </FormControl>
            <FormControl
              fullWidth
              className={!isFocused1 ? classes.customInput : ""}
            >
              <p>{workoutDescription}</p>
            </FormControl>
          </div>
        </div>

        <DragDropContext
          onDragEnd={handleOnDragEnd}
          className="h-100"
          isDragDisabled={true}
        >
          <Droppable droppableId="characters" isDragDisabled={true}>
            {(provided) => (
              <div
                className="characters"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {/* <form onSubmit={handleSubmit}> */}

                <WorkoutExercisesAnswerUI
                  addArray={addArray}
                  setaddArray={setaddArray}
                  setModalChangeExe={props.setModalChangeExe}
                  original={original}
                />

                {addArrayExtra.length > 0 && (
                  <>
                    <div className="container my-4">
                      <h2>
                        <Icon icon="mdi:bicycle-electric" /> Extra work
                      </h2>
                      These exercises aren’t part of the original workout
                    </div>
                    <WorkoutExercisesUI
                      addArray={addArrayExtra}
                      setaddArray={setaddArrayExtra}
                      setModalChangeExe={props.setModalChangeExe}
                    />
                  </>
                )}
                <div className="text-start  my-4">
                  <button
                    className="small-contained-button "
                    onClick={() => {
                      handleAdd();
                    }}
                  >
                    Add Extra Work
                  </button>
                </div>
                <div className=" my-2">
                  <TextField
                    id="outlined-basic"
                    // label="Exercise"
                    size="small"
                    variant="outlined"
                    placeholder="Add Comment here!"
                    label="Comment"
                    type="number"
                    value={comment}
                    name="description"
                    multiline
                    rows={5}
                    onChange={(e) => setCommnet(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: "14px" },
                    }}
                    InputProps={{
                      style: { fontSize: "14px" },
                    }}
                    sx={{
                      borderRadius: "5px",
                      // "& legend": { display: "none" },
                      // "& fieldset": { top: 0 },
                      mt: 2,
                      width: "100%",
                    }}
                  />
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
});
export default AddWorkoutCalender;
