import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSnackbar } from "notistack";
import {
  TextField,
  Container,
  Button,
  FormControl,
  Typography,
  FormGroup,
  Grid,
  Box,
  Stack,
  IconButton,
  Card,
  Checkbox,
  Radio,
  Backdrop,
  CircularProgress,
  Divider,
  RadioGroup,
  FormControlLabel,
  Chip,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { getSurveyTemplateDetail } from "src/DAL/surveyTemplates";
import { logo } from "src/assets";
// import { getFeedbackDetail, submitForm } from "src/DAL/form_template";
import moment from "moment";
import RatingComponent from "./component/RaingStylesForFeedback";
import { submitForm } from "src/DAL/Forms/forms";
import { makeStyles } from "@mui/styles";
import AvatarGallery from "./component/imageViewOnLength";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function UserInformationform() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  // const location = useLocation();
  const { state } = useLocation();
  const param = useParams();
  const _id = param.slug;
  const [templateTitle, setTemplateTitle] = useState("");
  // const anchorRef = useRef(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [questions, setQuestions] = useState([]);

  // const allAnswer = useMemo(() => {
  //   let totalAnswer = 0;
  //   questions.map((item) => {
  //     if (item.type == "multiple_select") {
  //       if (item.multiple_answers.length > 0) {
  //         totalAnswer = totalAnswer + 1;
  //       }
  //     } else {
  //       if (item.answer) {
  //         totalAnswer = totalAnswer + 1;
  //       }
  //     }
  //   });
  //   return totalAnswer === questions.length;
  // }, [questions]);
  // console.log(allAnswer, "dslkfajk");
  const handleBack = () => {
    navigate(-1);
  };

  const handleChangeText = (e, index) => {
    questions[index].answer = e.target.value;
    setQuestions([...questions]);
  };
  const handleChangeDescription = (e, index) => {
    questions[index].description = e.target.value;
    setQuestions([...questions]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let noAnswer = false;
    let question = [];

    const answers = [...questions];
    answers.map((item, index) => {
      if (item?.type == "image") {
        if (item?.required) {
          if (item?.multiple_answers.length != item?.options_count) {
            question.push(index + 1);
            enqueueSnackbar(
              `Please upload all images in the Question:${index + 1}`,
              {
                variant: "error",
              }
            );
            noAnswer = true;
          } else {
            for (let i = 0; i < item?.options_count; i++) {
              let errorGot = false;
              if (!item?.multiple_answers[i]) {
                question.push(index + 1);
                enqueueSnackbar(
                  `Please upload all images in the Question:${index + 1}`,
                  {
                    variant: "error",
                  }
                );
                noAnswer = true;
                errorGot = true;
                break;
              }
            }
            // if (!item?.multiple_answers[0]) {
            //   question.push(index + 1);
            //   enqueueSnackbar(
            //     `Please upload all images in the Question:${index + 1}`,
            //     {
            //       variant: "error",
            //     }
            //   );
            //   noAnswer = true;
            // }
          }
        }
        return;
      } else if (
        item?.required &&
        item?.type == "multiple_select" &&
        item?.multiple_answers.length == 0
      ) {
        console.log(item?.multiple_answers.length, "sdfkjhakjsdhkjfahkj");
        noAnswer = true;
        enqueueSnackbar(`Please answer the Question:${index + 1}`, {
          variant: "error",
        });
        return;
      } else if (
        (item?.type == "free_text" || item?.type == "multiple_choice") &&
        item?.required &&
        item?.answer == ""
      ) {
        noAnswer = true;
        enqueueSnackbar(`Please answer the Question:${index + 1}`, {
          variant: "error",
        });
        return;
      }
    });
    console.log(answers, "adslkjfjakaj");

    if (!noAnswer) {
      const answersTosent = [...answers];
      answers.map((item, index) => {
        if (item?.type == "image") {
          delete item.loading;
          item.answer = "";
        }
      });

      let postData = {
        questions: answersTosent,
      };
      console.log(postData, _id, "postData");

      try {
        setIsLoading(true);
        const res = await submitForm(_id, postData);
        if (res.code === 200) {
          navigate(-1);
          enqueueSnackbar(res.message, { variant: "success" });
        } else {
          setIsLoading(false);
          enqueueSnackbar(res.message, { variant: "error" });
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    }
    // else {
    //   enqueueSnackbar(`Please Answer the Question:${question[0]}`, {
    //     variant: "error",
    //   });
    //   return;
    // }
  };

  useEffect(() => {
    console.log(questions, "djgfjsdahaf");
  }, [questions]);
  useEffect(() => {
    setTemplateTitle(state);
    state?.questions?.map((item) => {
      if (item.type == "image") {
        let lenght = item?.options_count;
        let newArray = Array.from({ lenght }, () => "");
        item.answer = [...newArray];
        item.multiple_answers = [...newArray];
        item.loading = [...newArray];
      }
    });

    setQuestions([...state?.questions]);

    console.log(state, "sdafjkajsdahf");
  }, [state]);
  //===================================================================
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <Container sx={{ width: { sm: 950, md: 950 } }}>
      <Stack
        direction="column"
        alignItems="left"
        justifyContent="space-between"
        mb={4.5}
      >
        <div className="col-12">
          <IconButton
            className="back-screen-button mb-4"
            onClick={() => navigate(-1)}
          >
            <Icon icon="akar-icons:arrow-left" />
          </IconButton>
        </div>
      </Stack>{" "}
      <>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Stack
            direction="column"
            alignItems="center"
            spacing={1.5}
            justifyContent="space-between"
            mb={4.5}
          >
            <h2 className="text-start w-100">{templateTitle?.title}</h2>
            <Typography
              className="text-start w-100"
              sx={{ fontWeight: "500", fontSize: 16, opacity: 0.6 }}
            >
              {templateTitle?.description}
            </Typography>

            {questions &&
              questions.map((item, index) => {
                return (
                  <Card
                    sx={{
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      mb: 1,
                      width: { sm: "100%", md: "100%" },
                      margin: "auto",
                      p: 2,
                      backgroundColor: "",
                      borderRadius: 0.5,

                      position: "relative",
                    }}
                  >
                    {item?.required && (
                      <Chip
                        label={"Required"}
                        variant="contained"
                        color={"error"}
                        sx={{
                          fontSize: "10px",
                          height: "auto",
                          width: "auto",
                          position: "absolute",
                          top: 8,
                          left: 16,
                        }}
                      />
                    )}
                    <Box sx={{ width: "100%", margin: "auto" }} margin={0}>
                      <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 0, sm: 0, md: 2 }}
                        mt={1}
                      >
                        <Grid item md={12} xs={12}>
                          <div className="d-flex align-items-center mb-2">
                            <div className="">
                              <Typography
                                sx={{ fontWeight: "900", fontSize: 16, mr: 1 }}
                              >
                                Q{index + 1}.
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                variant="body1"
                                sx={{ fontSize: 14, mr: 1 }}
                              >
                                {item?.title}
                              </Typography>
                            </div>
                          </div>
                        </Grid>

                        {item?.type == "multiple_choice" ? (
                          <Grid item md={12} xs={12}>
                            <FormControl>
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={item?.answer}
                                onChange={(e) => {
                                  questions[index].answer = e.target.value;
                                  setQuestions([...questions]);
                                }}
                              >
                                {item?.options.map((item) => {
                                  return (
                                    <Grid item md={12}>
                                      <FormControlLabel
                                        value={item}
                                        control={<Radio />}
                                        label={item}
                                      />
                                    </Grid>
                                  );
                                })}{" "}
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        ) : item?.type == "multiple_select" ? (
                          <Grid item md={12} xs={12}>
                            <FormControl>
                              <FormGroup>
                                {item?.options.map((option) => (
                                  <Grid item md={12} key={option}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={questions[
                                            index
                                          ]?.multiple_answers.includes(option)}
                                          onChange={(e) => {
                                            const checked = e.target.checked;

                                            const updatedAnswers = checked
                                              ? [
                                                  ...questions[index]
                                                    ?.multiple_answers,
                                                  option,
                                                ]
                                              : questions[
                                                  index
                                                ]?.multiple_answers.filter(
                                                  (value) => value !== option
                                                );

                                            questions[index].multiple_answers =
                                              updatedAnswers;
                                            setQuestions([...questions]);
                                          }}
                                        />
                                      }
                                      label={option}
                                    />
                                  </Grid>
                                ))}
                              </FormGroup>
                            </FormControl>
                          </Grid>
                        ) : item?.type == "image" ? (
                          <Grid item md={12} xs={12}>
                            <div className="d-flex align-items-center mb-2">
                              <div className="">
                                <Typography
                                  sx={{
                                    fontWeight: "500",
                                    fontSize: 14,
                                    mr: 1,
                                  }}
                                >
                                  {item?.options[0]}
                                </Typography>
                              </div>

                              <AvatarGallery
                                length={item?.options_count}
                                Index={index}
                                questions={questions}
                                Image={item?.answer}
                                setQuestions={setQuestions}
                              />
                            </div>
                          </Grid>
                        ) : (
                          item?.type == "free_text" && (
                            <Grid item md={12} xs={12}>
                              <TextField
                                id="outlined-multiline-static"
                                label="Answer"
                                multiline
                                //   disabled
                                rows={4}
                                value={item?.answer}
                                sx={{ width: "100%" }}
                                onChange={(e) => handleChangeText(e, index)}
                              />
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Box>
                  </Card>
                );
              })}

            <div className="col-12 text-end">
              <Button variant="contained" type="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </Stack>
        </form>
      </>
    </Container>
  );
}
