import React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import fileTextFill from "@iconify/icons-eva/file-text-fill";
import { _get_is_seen_video_localStorage } from "src/DAL/localStorage/localStorage";
import ReactGA from "react-ga";

import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Link,
  Avatar,
} from "@mui/material";
import { get_root_value } from "src/utils/domUtils";
import { memberDetail } from "src/DAL/Profile/Profile";
import { useSnackbar } from "notistack";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import {
  DashboardIcons,
  AffirmationIcons,
  GoalStatmentIcons,
  GratitudeIcons,
  MemoriesIcons,
  PodsIcons,
  ProfileIcons,
  ProgrammeIcons,
  QuotesIcons,
  VaultIcons,
  DayPlanIcons,
} from "src/assets";
import { project_name } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { s3baseUrl } from "src/config/config";
import { ContentSettingApi } from "src/DAL/ContentSetting/ContentSetting";
// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  // color: theme.palette.text.secondary, //by Mohad bhai
  color: get_root_value("--sidebars-text-color"),
  // color: "white",
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: get_root_value("--portal-theme-primary"),
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const theme = useTheme();
  const isActiveRoot = active(item);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState(isActiveRoot);
  const [openDropMenu, setopenDropMenu] = useState(true);
  const handleClick = () => {
    setopenDropMenu(!open);
  };

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: theme.palette.primary.main,
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    "&:before": { display: "block" },
  };
  const activeRootStyles = {
    // color: get_root_value("--sidebar-selected-color"),
    color: get_root_value("--portal-theme-primary"),
    // color: "gold",
    fontWeight: "fontWeightMedium",

    "&:before": { display: "block" },
  };

  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  };
  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle className="sidebar-icon">
            {icon && icon}
          </ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: (theme) =>
                          theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          bgcolor: "primary.main",
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle
        sx={{
          ...(isActiveRoot && activeRootStyles),
        }}
      >
        {icon && icon}
      </ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const location = useLocation();
  const { pathname } = useLocation();
  const is_seen_video = _get_is_seen_video_localStorage();
  const { enqueueSnackbar } = useSnackbar();
  const [openDropDown, setOpenDropDown] = React.useState(false);
  const navigate = useNavigate();
  const [planActive, setPlanActive] = React.useState(false);
  const [trackerActive, setTrackerActive] = React.useState(false);
  const {
    handleContentSettingData,
    handleDashboardSettingData,
    handleDashboardTestimonialData,
    handleDashboardEventData,
    handleAdminTimeZone,
    handleUserInfo,
    handleConsultantInfo,
    navbarList,
    dashboardSettingData,
    consultantInfo,
  } = useContentSetting();
  const handleClickDropDown = () => {
    setOpenDropDown(!openDropDown);
  };
  const getImageIcon = (name) => <img src={name} width={22} height={22} />;
  const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

  const DayPlanIcon =
    project_name == "DD" || project_name == "BASIC_DEV"
      ? getImageIcon(DayPlanIcons)
      : getIcon(fileTextFill);

  const handleNavigatePlan = () => {
    navigate(`/ninety-day-plan`);
  };
  const handleNavigateTracker = () => {
    navigate(`/ninety-day-tracker`);
  };

  const getContentSetting = async () => {
    const result = await ContentSettingApi();
    if (result.code === 200) {
      console.log("called", result);
      if (result.content_setting === null) {
        handleDashboardSettingData({});
        handleContentSettingData({});
        handleDashboardTestimonialData([]);
        handleDashboardEventData([]);
        handleAdminTimeZone("");
        return;
      }
      localStorage.setItem(
        `content_setting`,
        JSON.stringify(result?.content_setting?.client_content_setting)
      );
      localStorage.setItem(
        `client_dashboard_setting`,
        JSON.stringify(result?.content_setting?.client_dashboard_setting)
      );
      localStorage.setItem(`testmonial`, JSON.stringify(result?.testmonial));
      localStorage.setItem(`home_event`, JSON.stringify(result?.home_event));
      localStorage.setItem(
        `admin_time_zone`,
        JSON.stringify(result?.time_zone)
      );
      handleAdminTimeZone(result?.time_zone);
      handleDashboardEventData(result?.home_event);
      handleDashboardTestimonialData(result?.testmonial);
      handleContentSettingData(result?.content_setting.client_content_setting);
      handleDashboardSettingData(
        result?.content_setting?.client_dashboard_setting
      );
      if (
        dashboardSettingData.video_url !==
          result.content_setting.client_dashboard_setting.video_url ||
        result.content_setting.client_dashboard_setting.video_type == "repeat"
      ) {
        localStorage.setItem("is_seen_video", "No");
      }
      ReactGA.initialize(
        result.content_setting.client_general_setting.analytic_google_code,
        { debug: true }
      );
      const member_result = await memberDetail();
      if (member_result.code === 200) {
        // console.log(member_result, "sfdkdkajhakh");
        localStorage.setItem(
          `user_data`,
          JSON.stringify(member_result?.member)
        );
        localStorage.setItem(
          `consultant_data`,
          JSON.stringify(
            member_result?.member_coach ? member_result?.member_coach : []
          )
        );
        handleUserInfo(member_result?.member);
        handleConsultantInfo(member_result?.member_coach);
        if (is_seen_video && is_seen_video === "Yes") {
        } else {
          localStorage.setItem("is_seen_video", "No");
        }
      } else {
        enqueueSnackbar(member_result.message, { variant: "error" });
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getContentSetting();
  }, []);
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const match = ({ path, matches }) => {
    if (matches) {
      let is_active = false;
      matches.forEach((match_path) => {
        const match = match_path
          ? !!matchPath({ path: match_path, end: false }, pathname)
          : false;

        if (match) {
          is_active = true;
        }
      });
      return is_active;
    }
    return path ? !!matchPath({ path, end: false }, pathname) : false;
  };

  return (
    <Box {...other}>
      <List disablePadding>
        {navConfig.map((item, i) => {
          const array_length = navConfig.length - 1;
          if (navbarList && navbarList.day_plan && i == array_length) {
            return (
              <>
                <NavItem
                  key={item.title}
                  item={item}
                  active={match}
                  setPlanActive={setPlanActive}
                  setTrackerActive={setTrackerActive}
                />
                {(project_name === "DD" || project_name === "BASIC_DEV") && (
                  <ListItemButton
                    onClick={handleClickDropDown}
                    component="a"
                    sx={{
                      pl: 5,
                      color: get_root_value("--input-text-color"),
                      fontSize: "14px",
                    }}
                  >
                    <ListItemIcon>{DayPlanIcon}</ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: "14px" }}
                      primary="90 Day Plan"
                    />
                    {openDropDown ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                )}
                <Collapse in={openDropDown} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      // sx={{
                      //   pl: 5,
                      //   color: get_root_value("--input-text-color"),
                      //   fontSize: "14px",
                      // }}
                      component="a"
                      // href="/ninety-day-plan"
                      sx={{
                        pl: 7,
                        color: get_root_value("--input-text-color"),
                        fontSize: "14px",
                      }}
                      onClick={handleNavigatePlan}
                      className={
                        match({ path: "/ninety-day-plan" }) ? "menuActive" : ""
                      }
                    >
                      <ListItemIcon>{DayPlanIcon}</ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "14px" }}
                        primary="90 Day Formula"
                      />
                    </ListItemButton>
                    <ListItemButton
                      component="a"
                      sx={{
                        pl: 7,
                        color: get_root_value("--input-text-color"),
                      }}
                      onClick={handleNavigateTracker}
                      className={
                        match({ path: "/ninety-day-tracker" })
                          ? "menuActive"
                          : ""
                      }
                    >
                      <ListItemIcon>{DayPlanIcon}</ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ fontSize: "14px" }}
                        primary="90 Day Tracker"
                      />
                    </ListItemButton>
                    {/* <NavItem
                      key={item.title}
                      item={item}
                      active={match}
                      setPlanActive={setPlanActive}
                      setTrackerActive={setTrackerActive}
                    /> */}
                  </List>
                </Collapse>
              </>
            );
          }
          return <NavItem key={item.title} item={item} active={match} />;
        })}
      </List>
      {/* {consultantInfo !== null &&
        consultantInfo !== undefined &&
        consultantInfo !== "undefined" &&
        consultantInfo.team_type !== "team" &&
        consultantInfo.status === true && (
          <div className="associate-section">
            <p className="mt-3">Your Success Ambassador : </p>
            <div className="d-flex circle-image">
              <Avatar
                src={s3baseUrl + consultantInfo?.image.thumbnail_1}
                alt="photoURL"
              >
                {consultantInfo?.first_name[0] + consultantInfo?.last_name[0]}
              </Avatar>
              <p className="card-title mt-3 mb-0">
                {consultantInfo?.first_name + " " + consultantInfo?.last_name}
              </p>
            </div>
          </div>
        )} */}
    </Box>
  );
}
