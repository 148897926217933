import { useFormik } from "formik";
import { useState } from "react";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
// material
import { CircularProgress, Container, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
// components
import {
  ProgrammesCard,
  ProgrammesList,
  RecordNotFound,
} from "../../components/_dashboard/programmes";
//
import { programmesListing } from "../../DAL/Programmes/Programmes";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import ProgrammesCardFirstIndex from "src/components/_dashboard/programmes/ProgrammesCardFirstIndex";

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function Programmes() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [programmesData, setProgrammesData] = useState([]);
  const [firstProgrammeData, setFirstProgrammeData] = useState({});
  const { contentSettingData } = useContentSetting();
  const getProgrammesList = async () => {
    setIsLoading(true);
    const result = await programmesListing();
    if (result.code === 200) {
      console.log(result?.program?.program, "sdlkjkflajsdlk");
      // if (result.program?.program.length > 0) {
      //   setFirstProgrammeData(result.program?.program[0]);
      //   delete result.program?.program[0];
      // }
      setProgrammesData(result?.program?.program);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getProgrammesList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      {contentSettingData && contentSettingData.programs_description ? (
        <div
          className="dashboard_description"
          dangerouslySetInnerHTML={{
            __html: contentSettingData.programs_description,
          }}
        ></div>
      ) : (
        <h2>Programmes</h2>
      )}
      {Object.keys(programmesData).length > 0 ? (
        <>
          {/* <div className="row">
            <div className="col-12">
              <ProgrammesCardFirstIndex
                programm={firstProgrammeData}
                reload={getProgrammesList}
              />
            </div>
          </div> */}
          <ProgrammesList
            programmes={programmesData}
            reload={getProgrammesList}
          />
        </>
      ) : (
        <>
          <RecordNotFound title="Programmes" />
        </>
      )}
    </div>
  );
}
