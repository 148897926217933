import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { fixedProgramAcces } from "src/DAL/Programmes/Programmes";

function ConfirmationWithDate({
  open,
  setOpen,
  handleAgree,
  title,
  programId,
  reload,
}) {
  const [date, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleCloseDialog = () => {
    setOpen(false);
  };
  const ProgramAccess = async () => {
    setIsLoading(true);
    let postData = {
      program_id: programId,
      start_date: moment(date).format("YYYY-MM-DD"),
    };
    if (!date) {
      enqueueSnackbar("Please Select Date", { variant: "error" });
      return;
    }
    console.log(postData, "sdlkfjaklsdjfkal");
    const result = await fixedProgramAcces(postData);
    if (result.code === 200) {
      setIsLoading(false);
      reload();
      handleCloseDialog();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (open) {
      setDate(null);
    }
  }, [open]);
  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleCloseDialog()}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle>
          {title ? title : "Are you sure you want to take this action?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <TextField
              sx={{ mt: 2 }}
              id="outlined-basic"
              label="Start Date"
              variant="outlined"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Start Date"
              fullWidth
              required
              name="no_of_weeks"
              value={date}
              onChange={(e) => {
                setDate(e.target.value);
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="model-button-hover"
            onClick={() => handleCloseDialog()}
          >
            Cancel
          </Button>
          <Button
            className="model-button-hover"
            disabled={isLoading}
            onClick={ProgramAccess}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* New to solve delete issue  */}
    </>
  );
}

export default ConfirmationWithDate;
