import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { IconButton, DialogContent, DialogActions } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useState } from "react";

import CustomConfirmation from "src/components/CustomConfirmation";
import AddWorkoutCalender from "../AddWorkoutCalender";

export default function EditWorkoutPopup({
  open,
  setOpen,
  date,
  programme,
  data,
  reloadList,
  day,
}) {
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const childRef = React.useRef();
  const [modalChangeExe, setModalChangeExe] = useState(false);

  // Function to be called in the child component
  const handleParentButtonClick = () => {
    // Call the exposed function in the child component using the ref
    childRef.current.childButtonClickUpdate();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAgreeDelete = (value) => {
    // setDeleteDoc(value);
    setOpenCloseDialog(true);
  };
  const handleDelete = async () => {
    handleClose();
    setOpenCloseDialog(false);
  };
  React.useEffect(() => {
    if (open) {
      setModalChangeExe(false);
    }
  }, [open]);
  return (
    <div>
      <CustomConfirmation
        open={openCloseDialog}
        setOpen={setOpenCloseDialog}
        title={"Are you sure you want to Close ?"}
        handleAgree={handleDelete}
      />
      <Dialog
        open={open}
        // onClose={handleClose}
        // scroll="body"
        fullWidth
        maxWidth={"lg"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="show-dialog-on-full"
      >
        <div className="text-end mt-2 me-2">
          {modalChangeExe ? (
            <IconButton
              className="back-screen-button"
              onClick={handleAgreeDelete}
            >
              <CloseIcon />
            </IconButton>
          ) : (
            <IconButton className="back-screen-button" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          )}
        </div>

        <DialogContent
          sx={{
            paddingY: 0,
            height: "80vh", // Set a maximum height for the scrollable area
            overflowY: "auto", // Enable vertical scrolling when content overflows
          }}
        >
          <AddWorkoutCalender
            ref={childRef}
            date={date}
            programme={programme}
            setOpen={setOpen}
            editData={data}
            day={day}
            reloadList={reloadList}
            setModalChangeExe={setModalChangeExe}
            // setWorkoutAsTem={setWorkoutAsTem}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ textTransform: "inherit" }}
            onClick={handleParentButtonClick}
            size="small"
            color="primary"
            variant="contained"
            className="small-contained-dialog-button"
          >
            Save workout
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
